// Polyfill for Object.fromEntries
if (!Object.fromEntries) {
  Object.fromEntries = (entries) => {
    if (!entries || !entries[Symbol.iterator]) {
      throw new TypeError('Object.fromEntries requires that the input argument be iterable')
    }

    return [...entries].reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})
  }
}

export default Object.fromEntries
