import MicroModal from 'micromodal'
import * as Sentry from '@sentry/browser'

import supabaseClient from '../utils/supabase'
import fromEntries from '../utils/fromEntries'
import extractUTMParams from '../utils/extractUTMParams'
import handleButtonLoadingState from '../utils/handleButtonLoadingState'
import { ENV, ALERT_TIMEOUT } from '../utils/constants'
import isValidPassword from '../utils/isValidPassword'
import isValidEmail from '../utils/isValidEmail'
import updateAppState from '../global/updateAppState'

import getUser from '../user/getUser'
import logout from './logout'
import proceedGiftingSubscription from '../gifting/proceedGiftingSubscription'

const { SITE_URL, TCN_API_KEY } = ENV

const unverifiedEmailAlert = document.querySelector('.unverified-email-alert')
unverifiedEmailAlert.classList.add('hidden')
let verifyEmail

// Subscription price by plan
const SUBSCRIPTION_PRICE = {
  yearly: 72,
  monthly: 9
}

let signUpFormData

/**
 * Handle show and hide password
 */
const bindTogglingPassword = () => {
  const passwordContainers = document.querySelectorAll('.password-container')

  if (passwordContainers.length) {
    passwordContainers.forEach((passwordContainer) => {
      const passwordInput = passwordContainer.querySelector('.password-input')
      const showPassword = passwordContainer.querySelector('.show-password')
      const hidePassword = passwordContainer.querySelector('.hide-password')

      showPassword.addEventListener('click', () => {
        passwordInput.type = 'text'
        showPassword.classList.add('hidden')
        hidePassword.classList.remove('hidden')
      })

      hidePassword.addEventListener('click', () => {
        passwordInput.type = 'password'
        hidePassword.classList.add('hidden')
        showPassword.classList.remove('hidden')
      })
    })
  }
}

const authProviders = () => {
  const authGoogle = document.querySelector('.auth-google')
  if (authGoogle) {
    authGoogle.addEventListener('click', () => {
      supabaseClient.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${SITE_URL}/success`
        }
      })
    })
  }

  // const authX = document.querySelector('.auth-x')
  // const authFacebook = document.querySelector('.auth-facebook')
}

const openConfirmEmailModal = () => {
  // Confirm email modal
  MicroModal.show('confirm-email', {
    disableFocus: true
  })

  const confirmEmailForm = document.querySelector('.confirm-email-form')
  const emailInput = confirmEmailForm.querySelector('input[name="email"]')
  const emailConfirmationInput = confirmEmailForm.querySelector('input[name="email_confirmation"]')
  const confirmEmailMessage = confirmEmailForm.querySelector('.confirm-email-message')

  // Populate email input with email from signup form and reset Email Confirmation input and message
  emailInput.value = signUpFormData.email
  emailConfirmationInput.value = ''
  confirmEmailMessage.innerText = ''
  confirmEmailMessage.classList.add('hidden')
}

const bindConfirmEmailFormEvent = () => {
  const confirmEmailForm = document.querySelector('.confirm-email-form')

  if (!confirmEmailForm) {
    return
  }

  const confirmEmailMessage = confirmEmailForm.querySelector('.confirm-email-message')
  const utmParams = extractUTMParams()
  const confirmEmailButton = confirmEmailForm.querySelector('button[type="submit"]')

  confirmEmailForm.addEventListener('submit', async (event) => {
    event.preventDefault()

    confirmEmailMessage.innerText = ''
    confirmEmailMessage.classList.add('hidden')

    const formData = new FormData(event.target)
    const email = formData.get('email').trim()
    const emailConfirmation = formData.get('email_confirmation').trim()

    // Check if email is valid
    if (!isValidEmail(email, confirmEmailMessage)) return

    // Check if emails match
    if (email.toLowerCase() !== emailConfirmation.toLowerCase()) {
      confirmEmailMessage.innerText = 'Looks like those emails don\'t match, can you check and try again?'
      confirmEmailMessage.classList.remove('hidden')

      return
    }

    // Update signUpFormData with confirmed email
    signUpFormData = Object.assign(signUpFormData, {}, { email })

    // Set loading state
    handleButtonLoadingState(confirmEmailButton, true)

    try {
      const fullName = signUpFormData.name
      let firstName = ''
      let lastName = ''

      if (fullName.includes(' ')) {
        const nameParts = signUpFormData.name.split(' ')

        firstName = nameParts[0]
        lastName = nameParts.slice(1).join(' ')
      } else {
        firstName = fullName
      }

      const response = await supabaseClient.auth.signUp({
        email: signUpFormData.email,
        password: signUpFormData.password,
        options: {
          data: {
            name: fullName,
            first_name: firstName,
            last_name: lastName,
            email_confirmed: false
          },
          emailRedirectTo: `${SITE_URL}/account/?confirmed=true&${utmParams}`
        }
      })

      const { data = {}, error = {} } = response
      const { user = {} } = data

      if (!response.error) {
        const {
          id, email: userEmail, created_at, identities
        } = user

        // Event tracking
        try {
          analytics.identify(id, {
            email: userEmail,
            createdAt: created_at
          })
        } catch (err) {
          console.error(err)
        }

        // If existing user
        if (identities.length === 0) {
          confirmEmailMessage.innerHTML = 'User already registered. <a href="/login/" class="underline">Sign in</a>'
          confirmEmailMessage.classList.remove('hidden')

          // Set loading state
          handleButtonLoadingState(confirmEmailButton, false)

          return
        }

        // Send magic link for email confirmation
        await supabaseClient.auth.signInWithOtp({
          email: signUpFormData.email
        })

        // Log the user back in after OTP signin logs them out
        await supabaseClient.auth.signInWithPassword({
          email: signUpFormData.email,
          password: signUpFormData.password
        })

        const selectedPlan = localStorage.getItem('tcn-selected-plan')

        // Save the userData to localStorage
        localStorage.setItem('userData', JSON.stringify(response.data))

        const isGifting = JSON.parse(localStorage.getItem('isGifting'))

        // Proceed gifting subscription
        if (isGifting) {
          proceedGiftingSubscription()

          return
        }

        // If no plan selected, redirect to join page
        if (!selectedPlan) {
          window.location = `/join/?${utmParams}#join`

          return
        }

        try {
          // Segment event Account Created
          analytics.identify(user.id)
        } catch (err) {
          console.error(err)
        }
        // Redirect to stripe checkout
        const apiResponse = await fetch('/api/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': TCN_API_KEY
          },
          body: JSON.stringify({
            plan: selectedPlan || 'yearly',
            userId: user.id,
            cancel_url: `${SITE_URL}/?${utmParams}`,
            utmParams
          })
        })

        const redirectUrl = await apiResponse.text()

        const plan = selectedPlan || 'yearly'

        try {
          // Segment event Add to Cart
          analytics.track('Add to Cart', {
            plan,
            price: SUBSCRIPTION_PRICE[plan],
            currency: 'USD',
            email: user.email,
            username: user.user_metadata.name
          })
        } catch (err) {
          console.error(err)
        }

        window.location = redirectUrl
      } else {
        const { message } = error

        confirmEmailMessage.innerHTML = message
        confirmEmailMessage.classList.remove('hidden')

        // Set loading state
        handleButtonLoadingState(confirmEmailButton, false)
      }
    } catch (error) {
      confirmEmailMessage.textContent = error.message
      confirmEmailMessage.classList.remove('hidden')

      // Set loading state
      handleButtonLoadingState(confirmEmailButton, false)
    }
  })
}

const bindSignupForm = () => {
  const signupForm = document.querySelector('.signup-email-password')

  if (!signupForm) {
    return
  }

  const signupMessage = signupForm.querySelector('.signup-message')

  signupForm.addEventListener('submit', async (event) => {
    signupMessage.classList.add('hidden')
    event.preventDefault()

    const formData = new FormData(event.target)
    const email = formData.get('email').trim()
    const password = formData.get('password')
    const name = formData.get('name')

    signUpFormData = {
      email,
      password,
      name
    }

    // Check if email is valid
    if (!isValidEmail(email, signupMessage)) return

    // Validate password
    if (!isValidPassword(password, signupMessage)) return

    openConfirmEmailModal()
  })
}

const login = () => {
  const loginEmailPass = document.querySelector('.login-email-password')

  if (!loginEmailPass) {
    return
  }

  const utmParams = extractUTMParams()

  const loginMessage = loginEmailPass.querySelector('.login-message')
  const loginButton = loginEmailPass.querySelector('button[type="submit"]')
  const loginButtonText = loginButton.querySelector('span')
  const loginLoader = loginButton.querySelector('.spinner')

  loginEmailPass.addEventListener('submit', (event) => {
    loginMessage.classList.add('hidden')
    event.preventDefault()

    const formData = new FormData(event.target)
    const remember = loginEmailPass.querySelector('[type="checkbox"]')
    const email = formData.get('email').trim()
    const password = formData.get('password')

    // Check if email is valid
    if (!isValidEmail(email, loginMessage)) return

    // Validate password
    if (!isValidPassword(password, loginMessage)) return

    // Set loading state
    loginButton.classList.add('opacity-50')
    loginButton.disabled = true
    loginLoader.classList.remove('hidden')
    loginButtonText.textContent = 'Signing in...'
    unverifiedEmailAlert.classList.add('hidden')

    supabaseClient.auth.signInWithPassword({
      email,
      password,
      options: {
        emailRedirectTo: `${SITE_URL}/`
      }
    })
      .then((response) => {
        if (!response.error) {
          // Save the userData to localStorage
          const userData = response.data
          const { user } = userData

          if (remember.checked) {
            user.remember = true
          }
          localStorage.setItem('userData', JSON.stringify(userData))

          // Event tracking
          const {
            id, email: userEmail, created_at, user_metadata
          } = user
          let plan = ''
          if (user_metadata.subscription_id) {
            plan = user_metadata.subscription_id
          }

          try {
            analytics.identify(id, {
              email: userEmail,
              plan,
              createdAt: created_at
            })
          } catch (error) {
            console.error(error)
          }

          const isGifting = JSON.parse(localStorage.getItem('isGifting'))

          // Proceed gifting subscription
          if (isGifting) {
            proceedGiftingSubscription()

            return
          }

          window.location = `/?${utmParams}`
        } else {
          const { error = {} } = response
          const { message } = error

          loginMessage.innerText = message
          loginMessage.classList.remove('hidden')

          // Show Unverified Email Alert if Email not confirmed
          if (message === 'Email not confirmed') {
            verifyEmail = email
            unverifiedEmailAlert.classList.remove('hidden')
          }

          // Set loading state
          loginButton.classList.remove('opacity-50')
          loginButton.disabled = false
          loginLoader.classList.add('hidden')
          loginButtonText.textContent = 'Sign in'
        }
      })
  })
}

const loginOtp = () => {
  const loginOtpForm = document.querySelector('.login-otp')

  if (!loginOtpForm) {
    return
  }

  const loginOtpMessage = loginOtpForm.querySelector('.login-otp-message')
  const loginOtpSuccess = document.querySelector('.login-otp-success')
  const loginOtpButton = loginOtpForm.querySelector('button[type="submit"]')
  const loginOtpButtonText = loginOtpButton.querySelector('span')
  const loginOtpLoader = loginOtpButton.querySelector('.spinner')

  loginOtpForm.addEventListener('submit', async (event) => {
    loginOtpMessage.innerHTML = ''
    loginOtpMessage.classList.add('hidden')
    loginOtpSuccess.classList.add('hidden')

    event.preventDefault()

    const formData = new FormData(event.target)
    const email = formData.get('email').trim()

    // Check if email is valid
    if (!isValidEmail(email, loginOtpMessage)) return

    // Set loading state
    loginOtpButton.classList.add('opacity-50')
    loginOtpButton.disabled = true
    loginOtpLoader.classList.remove('hidden')
    loginOtpButtonText.textContent = 'Processing...'
    unverifiedEmailAlert.classList.add('hidden')

    // Send magic link for email confirmation
    const response = await supabaseClient.auth.signInWithOtp({
      email
    })

    if (!response.error) {
      // Hide error message
      loginOtpMessage.classList.add('hidden')

      // Show success alert
      loginOtpSuccess.classList.remove('hidden')
    } else {
      const { error = {} } = response
      const { message } = error

      // Show error message
      loginOtpMessage.innerText = message
      loginOtpMessage.classList.remove('hidden')
    }

    // Set loading state
    loginOtpButton.classList.remove('opacity-50')
    loginOtpButton.disabled = false
    loginOtpLoader.classList.add('hidden')
    loginOtpButtonText.textContent = 'Send Magic Link'
  })
}

const confirmOtp = () => {
  const otpForm = document.querySelector('.confirm-otp')

  if (!otpForm) {
    return
  }

  const otpMessage = otpForm.querySelector('.otp-message')
  const otpButton = otpForm.querySelector('button[type="submit"]')
  const otpButtonText = otpButton.querySelector('span')
  const otpLoader = otpButton.querySelector('.spinner')
  const otpToken = otpForm.querySelector('[name="token"]')
  const { email, token } = fromEntries(new URLSearchParams(window.location.search))

  // Set token from params
  otpToken.value = token

  otpForm.addEventListener('submit', async (event) => {
    event.preventDefault()

    const formData = new FormData(event.target)

    // Set loading state
    otpButton.classList.add('opacity-50')
    otpButton.disabled = true
    otpLoader.classList.remove('hidden')
    otpButtonText.textContent = 'Confirming...'

    try {
      if (!email) {
        throw Error('Please click the confirmation link from your email')
      }

      const decodedEmail = decodeURIComponent(email)

      const response = await supabaseClient.auth.verifyOtp({
        email: decodedEmail,
        token: formData.get('token'),
        type: 'email'
      })

      const { error = {} } = response

      if (!response.error) {
        const updateResponse = await supabaseClient.auth.updateUser({
          data: { email_confirmed: true }
        })

        if (!updateResponse.error) {
          // Save the userData to localStorage
          const userData = updateResponse.data
          localStorage.setItem('userData', JSON.stringify(userData))

          window.location = '/'
        }
      } else {
        let { message } = error

        // If code is invalid or expired
        if (message === 'Token has expired or is invalid') {
          message += ` . <button type="button" class="resend-otp-email underline">Resend email to ${email}</a>`
        }

        otpMessage.innerHTML = message
        otpMessage.classList.remove('hidden')

        const resendOtpEmail = document.querySelector('.resend-otp-email')
        const resendEmailAlert = document.querySelector('.resend-email-alert')
        if (resendOtpEmail) {
          resendOtpEmail.addEventListener('click', async () => {
            // Hide OTP message
            otpMessage.classList.add('hidden')

            // Send magic link for email confirmation
            const resendResponse = await supabaseClient.auth.signInWithOtp({
              email
            })

            if (!resendResponse.error) {
              // Show success alert
              resendEmailAlert.classList.remove('hidden')

              // Hide success alert
              setTimeout(() => {
                resendEmailAlert.classList.add('hidden')
              }, ALERT_TIMEOUT)
            } else {
              const { error: resendError = {} } = resendResponse
              const { message: resendMessage } = resendError

              otpMessage.innerHTML = resendMessage
              otpMessage.classList.remove('hidden')
            }
          })
        }

        // Set loading state
        otpButton.classList.remove('opacity-50')
        otpButton.disabled = false
        otpLoader.classList.add('hidden')
        otpButtonText.textContent = 'Confirm'
      }
    } catch (error) {
      console.error(error)

      otpMessage.innerText = error
      otpMessage.classList.remove('hidden')

      // Set loading state
      otpButton.classList.remove('opacity-50')
      otpButton.disabled = false
      otpLoader.classList.add('hidden')
      otpButtonText.textContent = 'Confirm'
    }
  })
}

const bindResendEmail = () => {
  const resendEmail = unverifiedEmailAlert.querySelector('.resend-email')
  const resendEmailAlert = document.querySelector('.resend-email-alert')
  const userData = getUser()

  resendEmail.addEventListener('click', async () => {
    resendEmail.disabled = true
    resendEmail.classList.add('cursor-not-allowed')

    if (userData.user) {
      verifyEmail = userData.user.email
    }

    // Send magic link for email confirmation
    const response = await supabaseClient.auth.signInWithOtp({
      email: verifyEmail
    })

    resendEmail.disabled = false
    resendEmail.classList.remove('cursor-not-allowed')

    if (!response.error) {
      // Show success alert
      resendEmailAlert.classList.remove('hidden')

      // Hide success alert
      setTimeout(() => {
        resendEmailAlert.classList.add('hidden')
      }, ALERT_TIMEOUT)
    } else {
      const { error = {} } = response
      const { message } = error

      console.error(message)
    }
  })
}

const isUserSubscribed = async () => {
  const userData = getUser()

  if (userData) {
    const { user = {} } = userData
    const { user_metadata = {} } = user
    const { subscribed } = user_metadata

    return subscribed
  }

  return false
}

const redirectUser = async () => {
  const userData = getUser()

  const utmParams = extractUTMParams()

  // If logged in, redirect to account page
  if (userData
      && (window.location.pathname === '/login/'
      || window.location.pathname === '/signup/')) {
    window.location = `/account/?${utmParams}`
  }

  if (userData && window.location.pathname === '/join/') {
    const isSubscribed = await isUserSubscribed()

    if (isSubscribed) {
      window.location = `/account/?${utmParams}`
    }
  }

  // If not logged in and tries to access account, redirect to login
  if (!userData && window.location.pathname === '/account/') {
    window.location = `/login/?${utmParams}`
  }
}

const redeemSubscription = async () => {
  const currentUrl = new URL(window.location.href)
  const { redeemToken } = fromEntries(new URLSearchParams(window.location.search))
  const redeemSubsAlert = document.querySelector('.redeem-subscription-alert')
  const redeemSubsMessage = redeemSubsAlert.querySelector('.message')

  if (redeemToken) {
    try {
      const userData = getUser()

      const response = await fetch('/api/redeem-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': TCN_API_KEY
        },
        body: JSON.stringify({
          userId: userData.user.id,
          redeemToken
        })
      })

      if (!response.error) {
        await supabaseClient.auth.updateUser({
          data: { email_confirmed: true }
        })

        await updateAppState()
        redeemSubsMessage.textContent = 'You have redeemed your Team Tucker gift subscription!'
        redeemSubsAlert.classList.add('bg-tcn-green')
        redeemSubsAlert.classList.remove('hidden')

        try {
          // Segment event Add to Cart
          analytics.track('Redeemed Subscription', {
            plan: 'yearly',
            price: SUBSCRIPTION_PRICE.yearly,
            currency: 'USD',
            email: userData.user.email
          })
        } catch (err) {
          console.error(err)
        }
      } else {
        // Show fail alert
        redeemSubsMessage.textContent = response.error
        redeemSubsAlert.classList.add('bg-tcn-red')
        redeemSubsAlert.classList.remove('hidden')
      }

      // Hide success alert
      setTimeout(() => {
        redeemSubsAlert.classList.add('hidden')

        // Delete redeemToken and type from the url and update url
        currentUrl.searchParams.delete('redeemToken')
        currentUrl.searchParams.delete('type')
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, document.title, currentUrl.toString())
      }, ALERT_TIMEOUT)
    } catch (error) {
      console.error(error)
    }
  }
}

const redeemSubscriptionFail = () => {
  const currentUrl = new URL(window.location.href)
  const { error } = fromEntries(new URLSearchParams(window.location.search))
  const redeemSubsAlert = document.querySelector('.redeem-subscription-alert')
  const redeemSubsMessage = redeemSubsAlert.querySelector('.message')

  if (error === 'token-redeemed') {
    // Show fail alert
    redeemSubsMessage.textContent = 'Your subscription has been activated!'
    redeemSubsAlert.classList.add('bg-tcn-green')
    redeemSubsAlert.classList.remove('hidden')

    // Hide fail alert
    setTimeout(() => {
      redeemSubsAlert.classList.add('hidden')

      // Delete error from the url and update url
      currentUrl.searchParams.delete('error')
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, document.title, currentUrl.toString())
    }, ALERT_TIMEOUT)
  }
}

const initAuth = () => {
  authProviders()
  bindSignupForm()
  login()
  loginOtp()
  confirmOtp()
  redirectUser()

  // Handle show/hide password
  bindTogglingPassword()

  const signupToJoin = document.querySelector('.signup-to-join')
  if (signupToJoin) {
    signupToJoin.addEventListener('click', () => {
      localStorage.removeItem('tcn-selected-plan')
    })
  }

  const manageAccount = document.querySelector('.manage-account')
  if (manageAccount) {
    const user = getUser()

    if (user) {
      manageAccount.href += `?prefilled_email=${encodeURIComponent(user.user.email)}`
    }
  }

  const logoutButtons = document.querySelectorAll('.logout')
  logoutButtons.forEach((logoutButton) => {
    logoutButton.addEventListener('click', () => {
      logout()
    })
  })

  supabaseClient.auth.onAuthStateChange(async (event) => {
    if (event === 'SIGNED_OUT') {
      // Remove all Sentry data from the current scope
      Sentry.getCurrentScope().clear()
      window.location = '/'
    }

    if (event === 'INITIAL_SESSION') {
      await updateAppState()
      await redeemSubscription()
    }

    if (event === 'SIGNED_IN') {
      const userData = getUser()
      const { user = {} } = userData
      const { id, email } = user

      // Attach user data to an event.
      Sentry.getCurrentScope().clear()
      Sentry.setContext('user', { id, email })
      Sentry.setUser({ email, id })
    }
  })
}

const auth = () => {
  initAuth()
  bindResendEmail()
  redeemSubscriptionFail()
  bindConfirmEmailFormEvent()
}

export default auth
