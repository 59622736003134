import { ENV } from '../utils/constants'
import timestampToSeconds from '../utils/timestampToSeconds'

import getUser from '../user/getUser'
import isUserSubscribed from '../user/isUserSubscribed'

import setVideoPlayer from './setVideoPlayer'
import getSignedUrl from './getSignedUrl'

const { TCN_API_KEY } = ENV

/* eslint-disable no-underscore-dangle */
const postPage = document.querySelector('body.post')
const videoPlayer = document.querySelector('.video-player iframe')

let player
if (videoPlayer) {
  // eslint-disable-next-line no-undef
  player = Stream(videoPlayer)
}

const _enableTranscriptTimestamps = () => {
  const transcriptContent = document.querySelector('.video-transcript')

  if (transcriptContent && player) {
    const timestamps = transcriptContent.querySelectorAll('.timestamp')

    timestamps.forEach((timestamp) => {
      timestamp.addEventListener('click', () => {
        const cleanedTimestamp = timestamp.textContent.trim().replace(/\[|\]/g, '')
        document.documentElement.scrollTop = 0
        player.currentTime = timestampToSeconds(cleanedTimestamp)
        player.play()
      })
    })
  }
}

const _formatTranscript = () => {
  const videoTranscript = document.querySelector('.video-transcript div')

  if (!videoTranscript) {
    return
  }

  // Get all <p> elements within the div with class "transcript-content"
  const transcriptParagraphs = videoTranscript.querySelectorAll('p')

  // Iterate through each <p> element
  transcriptParagraphs.forEach((paragraph) => {
    // Check if the content of the paragraph contains <o:p>&nbsp;</o:p>
    if (paragraph.innerHTML.includes('&nbsp;')) {
      // Remove the paragraph
      paragraph.remove()
    }
  })

  // Get all <span> elements within the p tags
  const timestamps = videoTranscript.querySelectorAll('p span')

  // Define a regular expression to match timestamps like [00:00:00]
  const timestampRegex = /\[\d{2}:\d{2}:\d{2}\]/

  // Iterate through each <span> element
  timestamps.forEach((span) => {
    // Check if the content of the span matches the timestamp format
    if (timestampRegex.test(span.innerHTML)) {
      // Add the "timestamp" class to the span
      span.classList.add('timestamp')
    }
  })

  _enableTranscriptTimestamps()
}

const _getDecodedTranscript = async () => {
  const videoTranscript = document.querySelector('.video-transcript')
  const videoTranscriptEncrypted = document.querySelector('.video-transcript-encrypted')

  if (!videoTranscript) {
    return
  }

  const userData = await getUser()
  const { user } = userData
  const { id } = user

  const encryptedData = videoTranscriptEncrypted.innerHTML.trim()

  const response = await fetch('/api/decrypt', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': TCN_API_KEY
    },
    body: JSON.stringify({
      encryptedData,
      userId: id
    })
  })

  if (!response.ok) {
    console.error(`HTTP error! Status: ${response.status}`)
    return
  }

  const data = await response.json()
  const { decryptedData } = data

  if (!decryptedData) {
    return
  }

  videoTranscript.innerHTML = decryptedData
  videoTranscript.classList.remove('hidden')

  _formatTranscript()
}

const loadVideo = async () => {
  if (typeof videoDetails === 'undefined') return

  try {
    // eslint-disable-next-line no-undef
    const { id, isTeamTucker } = videoDetails
    const isSubscribed = await isUserSubscribed()
    const videoTranscript = document.querySelector('.video-transcript')
    const lockedVideoTranscript = document.querySelector('.locked-video-transcript')

    // If video is not paywalled
    if (!isTeamTucker) {
      // Play the video
      setVideoPlayer(id)

      if (!isSubscribed) {
        // If not subscribed, remove the transcript
        if (videoTranscript) {
          videoTranscript.remove()
        }

        // Show the lock overlay
        lockedVideoTranscript.classList.remove('hidden')
      } else {
        if (!videoTranscript) {
          return
        }

        // Remove the lock overlay
        if (lockedVideoTranscript) {
          lockedVideoTranscript.remove()
        }

        await _getDecodedTranscript()
      }
    }

    // If video is paywalled and user is subscribed
    if (isTeamTucker) {
      if (isSubscribed) {
        if (videoTranscript) {
          // Decode the transcript
          await _getDecodedTranscript()
        }

        // Play the video
        const signedUrl = await getSignedUrl(id)

        setVideoPlayer(signedUrl)
      } else {
        if (!videoTranscript) {
          return
        }

        videoTranscript.remove()
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const timestampMaps = () => {
  const timestampMap = document.querySelector('.timestamp-map')

  if (timestampMap && player) {
    const timestampMapItems = timestampMap.querySelectorAll('.timestamp')

    timestampMapItems.forEach((timestamp) => {
      const link = timestamp.querySelector('a')
      const toggle = timestamp.querySelector('.toggle')
      const description = timestamp.querySelector('p')
      const icon = timestamp.querySelector('svg')

      link.addEventListener('click', () => {
        document.documentElement.scrollTop = 0
        player.currentTime = timestampToSeconds(link.textContent.trim())
        player.play()
      })

      toggle.addEventListener('click', () => {
        description.classList.toggle('hidden')
        icon.classList.toggle('rotate-180')
      })
    })
  }
}

const bindSegmentEvents = async () => {
  if (typeof videoDetails === 'undefined') return

  const {
    id,
    isTeamTucker,
    content,
    category
    // eslint-disable-next-line no-undef
  } = videoDetails
  const isSubscribed = await isUserSubscribed()
  // If video is paywall and user is subscribed
  if (isTeamTucker && !isSubscribed) {
    try {
      // Segment event for viewing paywall
      analytics.track('videoPaywall_viewed', {
        videoId: id,
        content,
        category
      })
    } catch (err) {
      console.error(err)
    }
  }

  const joinButtons = document.querySelectorAll('.join-button-from-paywall')

  if (joinButtons.length) {
    joinButtons.forEach((button) => {
      button.addEventListener('click', () => {
        try {
          // Segment event for click on Join button on paywall
          analytics.track('videoPaywallJoinButton_clicked', {
            videoId: id,
            content,
            category
          })
        } catch (err) {
          console.error(err)
        }
      })
    })
  }

  // Segment event for video played
  player.addEventListener('play', () => {
    try {
      analytics.track('video_played', {
        videoId: id,
        content,
        category,
        currentTime: player.currentTime
      })
    } catch (err) {
      console.error(err)
    }
  })

  // Segment event for video ended before exiting out of the page
  window.addEventListener(
    'beforeunload',
    () => {
      try {
        analytics.track('video_watchedUntil', {
          videoId: id,
          content,
          category,
          currentTime: player.currentTime
        })
      } catch (err) {
        console.error(err)
      }
    }
  )
}

const post = () => {
  if (!postPage) return

  loadVideo()
  timestampMaps()
}

bindSegmentEvents()

export default post
