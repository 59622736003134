import runLaunchDarkly from '../global/launchdarkly'
import planSelector from './planSelector'
import redirectToCheckout from './redirectToCheckout'

const JOIN_PAGE_FLAG_KEYS = [
  'join-page-title',
  'join-page-main-description',
  'join-page-join-button',
  'join-page-monthly-button',
  'join-page-annual-button',
  'join-page-join-us-button',
  'join-page-secondary-title',
  'join-page-join-team-tucker-button',
  'join-page-join-to-gain-access-button',
  'join-page-join-the-movement-button'
]

const join = () => {
  planSelector()
  redirectToCheckout()
  runLaunchDarkly(JOIN_PAGE_FLAG_KEYS)
}

export default join
