const extractUTMParams = () => {
  const utmParams = {}
  const utmParamQueryString = new URLSearchParams(window.location.search)

  utmParamQueryString.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value
    }
  })

  return new URLSearchParams(utmParams).toString()
}

export default extractUTMParams
