export const getCookie = (name) => {
  // Get all cookies and split them into an array
  const cookieArray = document.cookie.split(';')

  // Find the cookie with the name we're looking for
  const cookieObject = cookieArray.find((cookie) => cookie.trim().startsWith(`${name}=`))

  if (!cookieObject) {
    return false
  }

  return cookieObject.trim().split('=')[1]
}

export const deleteCookie = (name, path = '/') => {
  // Set the cookie with the same name and path, but with an expiration date in the past
  document.cookie = `${name}=; path=${path}; secure; sameSite=strict; expires=Thu, 01 Jan 1970 00:00:00 GMT`
}
