import { ENV } from '../utils/constants'
import extractUTMParams from '../utils/extractUTMParams'

import getUser from '../user/getUser'
import isUserSubscribed from '../user/isUserSubscribed'

const {
  SITE_URL,
  TCN_API_KEY,
  SUBSCRIPTION_PRICE
} = ENV

const redirectToCheckout = () => {
  const userData = getUser()
  const joinButton = document.querySelector('.join-button')

  if (joinButton) {
    const utmParams = extractUTMParams()

    const joinButtonText = joinButton.querySelector('span')
    const joinLoader = joinButton.querySelector('.spinner')

    joinButton.addEventListener('click', async () => {
      if (userData) {
        const isSubscribed = await isUserSubscribed()

        if (!isSubscribed) {
          try {
            // Set loading state
            joinButton.classList.add('opacity-50')
            joinButton.disabled = true
            joinLoader.classList.remove('hidden')
            joinButtonText.textContent = 'Processing...'

            const { user } = userData

            const plan = localStorage.getItem('tcn-selected-plan') || 'yearly'

            // Redirect to stripe checkout
            const apiResponse = await fetch('/api/subscribe', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-API-Key': TCN_API_KEY
              },
              body: JSON.stringify({
                plan,
                userId: user.id,
                cancel_url: `${SITE_URL}/?${utmParams}`,
                utmParams
              })
            })

            const redirectUrl = await apiResponse.text()

            try {
              // Segment event Add to Cart
              analytics.track('Add to Cart', {
                plan,
                price: SUBSCRIPTION_PRICE[plan],
                currency: 'USD',
                email: user.email,
                username: user.user_metadata.name
              })
            } catch (err) {
              console.error(err)
            }

            window.location = redirectUrl
          } catch (error) {
            console.error(error)

            // Set loading state
            joinButton.classList.remove('opacity-50')
            joinButton.disabled = false
            joinLoader.classList.add('hidden')
            joinButtonText.textContent = 'Join now'
          }
        }
      } else {
        window.location = `/signup/?${utmParams}`
      }
    })
  }
}

export default redirectToCheckout
