const planSelector = () => {
  const plansContainer = document.querySelector('.plan-selector')

  if (!plansContainer) return

  localStorage.setItem('tcn-selected-plan', 'yearly')

  const plans = plansContainer.querySelectorAll('.plan')

  plans.forEach((plan) => {
    plan.addEventListener('click', () => {
      const { term } = plan.dataset

      localStorage.setItem('tcn-selected-plan', term)

      plans.forEach((p) => {
        p.classList.remove('bg-tcn-chocolate', 'text-white')
      })

      plan.classList.add('bg-tcn-chocolate', 'text-white')

      plansContainer.querySelector('.plan-monthly').classList.add('hidden')
      plansContainer.querySelector('.plan-yearly').classList.add('hidden')

      plansContainer.querySelector(`.plan-${term}`).classList.remove('hidden')
    })
  })
}

export default planSelector
