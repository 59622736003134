import global from './global/index'
import auth from './auth/index'
import account from './account/index'
import post from './post/index'
import join from './join/index'
import gifting from './gifting/index'

// Init all modules
global()
auth()
account()
post()
join()
gifting()
