import Flickity from 'flickity'

const postSliders = () => {
  const sliders = document.querySelectorAll('.posts-slider')

  sliders.forEach((flktySlider) => {
    if (flktySlider) {
      let flktyGroupCells = 1
      let flktySelectedAttraction = 0.2
      let flktyFriction = 0.8
      let flktyDragThreshold = 10

      if (matchMedia('(min-width:1281px)').matches) {
        flktyGroupCells = 2
        flktySelectedAttraction = 0.025
        flktyFriction = 0.28
        flktyDragThreshold = 5
      }

      // eslint-disable-next-line no-new
      new Flickity(flktySlider, {
        cellAlign: 'left',
        contain: true,
        groupCells: flktyGroupCells,
        prevNextButtons: false,
        pageDots: false,
        dragThreshold: flktyDragThreshold,
        selectedAttraction: flktySelectedAttraction,
        friction: flktyFriction,
        imagesLoaded: true,
        watchCSS: true
      })

      const onIntersection = (entries) => {
        entries.forEach((entry) => entry.target.classList.toggle('is-visible', entry.isIntersecting))
      }

      const flktyObserver = new IntersectionObserver(onIntersection, {
        root: null,
        threshold: 0
      })

      const lastElement = flktySlider.querySelector('.post .post-image .is-last')

      if (lastElement) {
        flktyObserver.observe(lastElement)
      }

      /* iOS 12-14 fix */
      let tapArea
      let startX
      // eslint-disable-next-line no-restricted-syntax
      for (const item of (tapArea = flktySlider.querySelectorAll('.posts-slider'), startX = 0, tapArea)) {
        // eslint-disable-next-line no-unused-expressions, no-loop-func
        item.ontouchstart = (t) => {
          startX = t.touches[0].clientX
        // eslint-disable-next-line no-loop-func
        }
        // eslint-disable-next-line no-loop-func
        item.ontouchmove = (t) => {
          // eslint-disable-next-line no-unused-expressions
          Math.abs(t.touches[0].clientX - startX) > 5 && t.cancelable && t.preventDefault()
        }
      }
    }
  })
}

export default postSliders
