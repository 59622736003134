const timestampToSeconds = (timestamp) => {
  // Split the timestamp into hours, minutes, and seconds
  const [hours, minutes, seconds] = timestamp.split(':').map(Number)

  // Calculate the total seconds
  const totalSeconds = hours * 3600 + minutes * 60 + seconds

  return totalSeconds
}

export default timestampToSeconds
