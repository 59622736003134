import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from './constants'

const isValidPassword = (password, error) => {
  if (password.length > PASSWORD_MAX_LENGTH) {
    const errorElement = error

    errorElement.innerHTML = `Maximum password length is ${PASSWORD_MAX_LENGTH} characters.`
    errorElement.classList.remove('hidden')

    return false
  }

  if (password.length < PASSWORD_MIN_LENGTH) {
    const errorElement = error

    errorElement.innerHTML = `Password must be at least ${PASSWORD_MIN_LENGTH} characters.`
    errorElement.classList.remove('hidden')

    return false
  }

  return true
}

export default isValidPassword
