import toggleElement from '../utils/toggleElement'

import getUser from '../user/getUser'

const setAuthenticated = () => {
  const notAuthenticatedElement = document.querySelectorAll('.not-authenticated')
  const authenticatedElement = document.querySelectorAll('.authenticated')
  const user = getUser()

  if (!user) {
    notAuthenticatedElement.forEach((element) => {
      toggleElement(element, false)
    })

    authenticatedElement.forEach((element) => {
      toggleElement(element, true)
    })
  } else {
    notAuthenticatedElement.forEach((element) => {
      toggleElement(element, true)
    })

    authenticatedElement.forEach((element) => {
      toggleElement(element, false)
    })
  }
}

export default setAuthenticated
