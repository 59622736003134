import * as LDClient from 'launchdarkly-js-client-sdk'
import getUser from '../user/getUser'
import { ENV } from '../utils/constants'

import announcementBanner from './announcementBanner'
import homepageHero from './homepageHero'

let ldClient
const { LAUNCHDARKLY_CLIENTSIDE_ID } = ENV

const getAnonymousUserId = () => {
  const anonymousUserId = localStorage.getItem('anonymousLdUserId')

  if (anonymousUserId) {
    return anonymousUserId
  }

  const newAnonymousUserId = Date.now()
  localStorage.setItem('anonymousLdUserId', newAnonymousUserId)
  return newAnonymousUserId
}

const initLaunchDarkly = () => {
  const userData = getUser()
  const userId = userData ? userData.user.id : getAnonymousUserId()
  const context = {
    kind: 'user',
    key: userId,
    userId
  }

  ldClient = LDClient.initialize(LAUNCHDARKLY_CLIENTSIDE_ID, context)
}

// Render data from flag key with JSON value
const renderDataFromObjectFlagKey = (flagValue, element) => {
  Object.keys(flagValue).forEach((key) => {
    switch (key) {
      case 'px':
        element.style.paddingLeft = `${flagValue[key]}px`
        element.style.paddingRight = `${flagValue[key]}px`
        break
      default:
        element.innerHTML = flagValue[key]
        break
    }
  })
}

const renderNewData = (flags) => {
  flags.forEach((flagKey) => {
    const flagValue = ldClient.variation(flagKey)
    const element = document.querySelector(`.${flagKey}`)

    if (flagKey === 'global-announcement-banner') {
      announcementBanner(flagValue)
      return
    }

    if (flagKey === 'home-page-hero') {
      homepageHero(flagValue)
      return
    }

    if (flagValue && element) {
      switch (typeof flagValue) {
        // JSON value
        case 'object':
          renderDataFromObjectFlagKey(flagValue, element)
          break
        // String value
        default:
          element.innerHTML = flagValue
          break
      }
    }
  })
}

const runLaunchDarkly = async (flags) => {
  if (!ldClient) {
    initLaunchDarkly()
  }

  try {
    ldClient.on('ready', () => {
      renderNewData(flags)
    })

    ldClient.on('change', () => {
      renderNewData(flags)
    })
  } catch (err) {
    console.error(err)
  }
}

export default runLaunchDarkly
