import MicroModal from 'micromodal'
import supabaseClient from '../utils/supabase'

import { ENV } from '../utils/constants'

import setManageAccount from './setManageAccount'
import getUser from '../user/getUser'
import refreshUser from '../user/refreshUser'
import isValidEmail from '../utils/isValidEmail'
import isValidPassword from '../utils/isValidPassword'
import resetPasswordState from '../auth/resetPasswordState'
import handleButtonLoadingState from '../utils/handleButtonLoadingState'

const { SITE_URL, STRIPE_PORTAL_URL, TCN_API_KEY } = ENV

const alert = document.querySelector('.alert')

const showAlert = (message) => {
  if (alert) {
    const alertText = alert.querySelector('span')

    alertText.innerHTML = message
    alert.classList.remove('hidden')
    alert.classList.add('flex')
  }
}

const closeAlert = () => {
  if (alert) {
    alert.classList.add('hidden')
  }
}

const loadAccount = async () => {
  // Refresh session on successful email confirmation
  if (window.location.pathname === '/account/' && window.location.search.includes('?confirmed=true')) {
    await refreshUser()
  }

  const userEmail = document.querySelector('.user-email')

  if (userEmail) {
    const userData = getUser()

    const { user = {} } = userData
    const { email } = user

    userEmail.innerHTML = email
  }
}

const changeEmail = () => {
  const changeEmailForm = document.querySelector('.change-email-form')

  if (!changeEmailForm) {
    return
  }

  const changeEmailMessage = changeEmailForm.querySelector('.change-email-message')
  const changeEmailButton = changeEmailForm.querySelector('button[type="submit"]')

  changeEmailForm.addEventListener('submit', async (event) => {
    event.preventDefault()

    // Remove error message
    changeEmailMessage.innerText = ''
    changeEmailMessage.classList.add('hidden')

    const formData = new FormData(event.target)
    const toEmail = formData.get('email').trim()
    const userData = getUser()
    const fromEmail = userData.user.email
    const accessToken = userData.session.access_token

    // Check if email is valid
    if (!isValidEmail(toEmail, changeEmailMessage)) return

    if (toEmail === fromEmail) {
      changeEmailMessage.innerHTML = 'You\'re already using this email address. Please use a different one!'
      changeEmailMessage.classList.remove('bg-tcn-red')
      changeEmailMessage.classList.add('bg-tcn-green')
      changeEmailMessage.classList.remove('hidden')

      return
    }

    // Set loading state
    handleButtonLoadingState(changeEmailButton, true)

    const apiResponse = await fetch('/api/change-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': TCN_API_KEY,
        AUTHORIZATION: accessToken
      },
      body: JSON.stringify({
        fromEmail,
        toEmail
      })
    })

    const responseBody = await apiResponse.json()

    if (!responseBody.error) {
      showAlert('Please check your email for confirmation.')

      MicroModal.close('change-email')
    } else {
      const { error = {} } = apiResponse
      const { message } = error

      changeEmailMessage.innerText = message
      changeEmailMessage.classList.remove('hidden')
    }

    // Set loading state
    handleButtonLoadingState(changeEmailButton, false)
  })
}

const changePassword = () => {
  const changePasswordForm = document.querySelector('.change-password-form')

  if (!changePasswordForm) {
    return
  }

  const changePasswordMessage = changePasswordForm.querySelector('.change-password-message')
  const changePasswordButton = changePasswordForm.querySelector('button[type="submit"]')

  changePasswordForm.addEventListener('submit', (event) => {
    event.preventDefault()

    // Remove error message
    changePasswordMessage.innerText = ''
    changePasswordMessage.classList.add('hidden')

    const formData = new FormData(event.target)

    // Validate password
    if (!isValidPassword(formData.get('password'), changePasswordMessage)) return

    if (formData.get('password') !== formData.get('password_confirmation')) {
      changePasswordMessage.innerText = 'Passwords Don\'t Match'
      changePasswordMessage.classList.remove('hidden')

      return
    }

    // Set loading state
    handleButtonLoadingState(changePasswordButton, true)

    supabaseClient.auth.updateUser({
      password: formData.get('password'),
      options: {
        emailRedirectTo: `${SITE_URL}/account/`
      }
    })
      .then((response) => {
        if (!response.error) {
          showAlert('Your password has been updated.')

          MicroModal.close('change-password')
        } else {
          const { error = {} } = response
          const { message } = error

          changePasswordMessage.innerText = message
          changePasswordMessage.classList.remove('hidden')
        }

        // Set loading state
        handleButtonLoadingState(changePasswordButton, false)
      })
  })
}

const initAccount = () => {
  changeEmail()
  changePassword()

  // Change email modal
  const changeEmailButton = document.querySelector('.change-email')
  if (changeEmailButton) {
    changeEmailButton.addEventListener('click', () => {
      MicroModal.show('change-email', {
        disableFocus: true
      })

      const changeEmailForm = document.querySelector('.change-email-form')
      const emailInput = changeEmailForm.querySelector('input[name="email"]')
      const changeEmailMessage = changeEmailForm.querySelector('.change-email-message')

      emailInput.value = ''
      changeEmailMessage.innerText = ''
      changeEmailMessage.classList.add('hidden')
    })
  }

  // Change password modal
  const changePasswordButton = document.querySelector('.change-password')
  if (changePasswordButton) {
    changePasswordButton.addEventListener('click', () => {
      MicroModal.show('change-password', {
        disableFocus: true
      })

      const changePasswordForm = document.querySelector('.change-password-form')
      const password = changePasswordForm.querySelector('input[name="password"]')
      const passwordConfirmation = changePasswordForm.querySelector('input[name="password_confirmation"]')
      const changePasswordMessage = changePasswordForm.querySelector('.change-password-message')

      // Reset password form
      password.value = ''
      passwordConfirmation.value = ''
      changePasswordMessage.innerText = ''
      changePasswordMessage.classList.add('hidden')

      resetPasswordState()
    })
  }

  // Manage account
  const manageAccount = document.querySelector('.manage-account')
  if (manageAccount) {
    manageAccount.addEventListener('click', () => {
      const userData = getUser()

      if (userData) {
        const { user = {} } = userData
        const { email } = user

        window.location = `${STRIPE_PORTAL_URL}?prefilled_email=${encodeURIComponent(email)}`
      }
    })
  }

  setManageAccount()
}

const forgotPassword = () => {
  const forgotPasswordForm = document.querySelector('.forgot-password-form')

  if (!forgotPasswordForm) {
    return
  }

  const submitButton = forgotPasswordForm.querySelector('button[type="submit"]')
  const submitLoader = submitButton.querySelector('.spinner')

  const forgotPasswordMessage = forgotPasswordForm.querySelector('.forgot-password-message')

  forgotPasswordForm.addEventListener('submit', (event) => {
    event.preventDefault()

    // Close last alert
    closeAlert()

    // Remove last error
    forgotPasswordMessage.classList.add('hidden')

    // Set loading state
    submitButton.classList.add('opacity-50')
    submitButton.disabled = true
    submitLoader.classList.remove('hidden')

    const formData = new FormData(event.target)
    const email = formData.get('email').trim()

    supabaseClient.auth.resetPasswordForEmail(email, {
      redirectTo: `${SITE_URL}/change_password`
    })
      .then((response) => {
        // Set loading state
        submitButton.classList.remove('opacity-50')
        submitButton.disabled = false
        submitLoader.classList.add('hidden')

        if (!response.error) {
          showAlert('If you have previously registered an account within our system, we\'ve sent you a link to reset your password.')
        } else {
          const { error = {} } = response
          const { message } = error

          console.error(`An error occurred in the 'Forgot Password' flow: ${message}`)

          forgotPasswordMessage.innerText = message
          forgotPasswordMessage.classList.remove('hidden')
        }
      })
  })
}

const resetPassword = () => {
  const resetPasswordForm = document.querySelector('.reset-password-form')

  if (!resetPasswordForm) {
    return
  }

  const submitButton = resetPasswordForm.querySelector('button[type="submit"]')
  const submitLoader = submitButton.querySelector('.spinner')
  const changePasswordMessage = resetPasswordForm.querySelector('.reset-password-message')

  const urlParams = new URLSearchParams(window.location.href)
  const errorCode = urlParams.get('error_code')
  const errorDescription = urlParams.get('error_description')

  if (errorCode || errorDescription) {
    console.error(`An error occurred in the 'Forgot Password' flow: ${errorCode}`)
    console.error(`An error occurred in the 'Forgot Password' flow: ${errorDescription}`)

    if (errorCode === '401' && errorDescription === 'Email link is invalid or has expired') {
      changePasswordMessage.innerHTML = 'The email link we\'ve send you has expired. Please try <a href="/forgot_password" class="underline">resetting your password</a> again.'
      changePasswordMessage.classList.remove('hidden')
    }
  }

  resetPasswordForm.addEventListener('submit', async (event) => {
    event.preventDefault()

    // Close last alert
    closeAlert()

    // Remove last error
    changePasswordMessage.classList.add('hidden')

    const formData = new FormData(event.target)
    const password = formData.get('password')
    const passwordConfirmation = formData.get('password_confirmation')

    // Validate password
    if (!isValidPassword(password, changePasswordMessage)) return

    if (password !== passwordConfirmation) {
      changePasswordMessage.innerText = 'The passwords don\'t match. Try again.'
      changePasswordMessage.classList.remove('hidden')

      // Set loading state
      submitButton.classList.remove('opacity-50')
      submitButton.disabled = false
      submitLoader.classList.add('hidden')

      return
    }

    // Set loading state
    handleButtonLoadingState(submitButton, true)

    supabaseClient.auth.updateUser({
      password,
      options: {
        emailRedirectTo: `${SITE_URL}/login`
      }
    })
      .then((response) => {
        // Set loading state
        handleButtonLoadingState(submitButton, false)

        if (!response.error) {
          showAlert('Your password was successfully updated. Please <a href="/login" class="underline">log in</a> with your new credentials.')
        } else {
          const { error = {} } = response
          const { message } = error

          console.error(`An error occurred in the 'Forgot Password' flow: ${message}`)

          changePasswordMessage.innerText = message
          changePasswordMessage.classList.remove('hidden')
        }
      })
  })
}

const account = () => {
  loadAccount()
  initAccount()
  forgotPassword()
  resetPassword()
}

export default account
