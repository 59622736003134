import getUser from './getUser'

const checkIfUnverifiedEmail = async () => {
  const unverifiedEmailAlert = document.querySelector('.unverified-email-alert')
  unverifiedEmailAlert.classList.add('hidden')
  const userData = getUser()

  // Show alert if email not confirmed
  if (
    userData
    && userData.user
    && userData.user.user_metadata
    && !userData.user.user_metadata.email_confirmed
  ) {
    unverifiedEmailAlert.classList.remove('hidden')
  }
}

export default checkIfUnverifiedEmail
