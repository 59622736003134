import getUser from '../user/getUser'

const setSubscribed = () => {
  const notSubscribedElement = document.querySelectorAll('.not-subscribed')
  const subscribedElement = document.querySelectorAll('.subscribed')
  const heroHomepage = document.querySelector('.hero-homepage')
  const heroHomepageImage = document.querySelector('.hero-homepage-image')

  const userData = getUser()

  let isSubscribed = false

  if (userData) {
    const { user = {} } = userData
    const { user_metadata } = user

    if (user_metadata.subscribed) {
      isSubscribed = true
    }
  }

  if (!isSubscribed) {
    notSubscribedElement.forEach((element) => {
      element.classList.remove('hidden')
    })

    subscribedElement.forEach((element) => {
      element.classList.add('hidden')
    })

    // Update homepage hero
    // if (heroHomepage) {
    //   heroHomepage.classList.remove('md:h-[580px]', 'h-[388px]')
    //   heroHomepage.classList.add('md:h-[845px]', 'h-[488px]')
    // }
    // if (heroHomepageImage) {
    //   heroHomepageImage.classList.remove('md:!object-[0px_-130px]')
    // }
  } else {
    notSubscribedElement.forEach((element) => {
      element.classList.add('hidden')
    })

    subscribedElement.forEach((element) => {
      element.classList.remove('hidden')
    })

    // Update homepage hero
    // if (heroHomepage) {
    //   heroHomepage.classList.remove('md:h-[845px]', 'h-[488px]')
    //   heroHomepage.classList.add('md:h-[480px]', 'h-[388px]')
    // }

    // if (heroHomepageImage) {
    //   heroHomepageImage.classList.add('md:!object-[0px_-130px]')
    // }
  }
}

export default setSubscribed
