import { createClient } from '@supabase/supabase-js'
import { ENV } from './constants'

const { SUPABASE_URL, SUPABASE_ANON_KEY } = ENV

const supabaseClient = createClient(
  SUPABASE_URL,
  SUPABASE_ANON_KEY
)

export default supabaseClient
