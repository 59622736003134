/**
 * Reset password input to default state with type as password and show password icon
 */
const resetPasswordState = () => {
  const passwordContainers = document.querySelectorAll('.password-container')

  if (passwordContainers.length) {
    passwordContainers.forEach((passwordContainer) => {
      const passwordInput = passwordContainer.querySelector('.password-input')
      const showPassword = passwordContainer.querySelector('.show-password')
      const hidePassword = passwordContainer.querySelector('.hide-password')

      passwordInput.type = 'password'
      showPassword.classList.remove('hidden')
      hidePassword.classList.add('hidden')
    })
  }
}

export default resetPasswordState
