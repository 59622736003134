/* eslint-disable no-undef */
const ENV = {
  SITE_URL,
  STRIPE_PORTAL_URL,
  EXPIRY_TIME_FOR_SESSION_IN_SECONDS,
  TCN_API_KEY,
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
  LAUNCHDARKLY_CLIENTSIDE_ID
}

const ANNUAL_PRICE = 72

const MAXIMUM_RECIPIENTS = 50

const ALERT_TIMEOUT = 10000

const PASSWORD_MAX_LENGTH = 72
const PASSWORD_MIN_LENGTH = 6

const SUBSCRIPTION_PRICE = {
  yearly: 72,
  monthly: 9
}

const TCN_CHECKOUT_COOKIE = 'tcn-checkout'

export {
  ENV,
  ANNUAL_PRICE,
  MAXIMUM_RECIPIENTS,
  ALERT_TIMEOUT,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  SUBSCRIPTION_PRICE,
  TCN_CHECKOUT_COOKIE
}
