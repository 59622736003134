const mobileNav = () => {
  const mobileNavContainer = document.querySelector('.mobile-nav')
  const mobileNavToggles = document.querySelectorAll('.mobile-nav-toggle')
  const mobileNavOverlay = document.querySelector('.mobile-nav-overlay')

  mobileNavToggles.forEach((mobileNavToggle) => {
    mobileNavToggle.addEventListener('click', () => {
      mobileNavOverlay.classList.toggle('hidden')
      mobileNavOverlay.classList.toggle('opacity-100')
      mobileNavOverlay.classList.toggle('opacity-0')
      mobileNavContainer.classList.toggle('-translate-x-full')
      mobileNavContainer.classList.toggle('translate-x-0')
      document.documentElement.classList.toggle('overflow-hidden')
    })
  })
}

export default mobileNav
