import { ENV } from '../utils/constants'

import getUser from '../user/getUser'

const { SITE_URL, TCN_API_KEY } = ENV

const proceedGiftingSubscription = async () => {
  const userData = getUser()
  const giftForm = JSON.parse(localStorage.getItem('giftForm'))
  const { toEmails, optIn } = giftForm

  try {
    const apiResponse = await fetch('/api/gift-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': TCN_API_KEY
      },
      body: JSON.stringify({
        plan: 'yearly',
        userId: userData.user.id,
        toEmails,
        optIn,
        cancel_url: `${SITE_URL}/`
      })
    })

    const redirectUrl = await apiResponse.text()

    // Clear isGifting from localStorage
    localStorage.removeItem('isGifting')

    window.location = redirectUrl
  } catch (error) {
    console.error(error)
  }
}

export default proceedGiftingSubscription
