import fromEntries from './fromEntries'

const trackUTM = () => {
  // Use URLSearchParams to get strings
  const deleteParams = []
  const utmParamQueryString = new URLSearchParams(window.location.search)

  utmParamQueryString.forEach((value, key) => {
    if (!key.startsWith('utm_')) deleteParams.push(key)
  })

  deleteParams.forEach(key => utmParamQueryString.delete(key))

  if (utmParamQueryString.toString()) {
    // Get all the links on the page
    document.querySelectorAll('a').forEach((originalItem) => {
      const item = originalItem
      const checkUrl = new URL(item.href)

      // Skip processing links that navigate to a different domain
      // eslint-disable-next-line no-restricted-globals
      if (checkUrl.host !== location.host) return

      let skip = false
      const linkSearchParams = new URLSearchParams(checkUrl.search)

      linkSearchParams.forEach((value, key) => {
        if (key.startsWith('utm_')) skip = true
      })

      if (skip) return

      // Merge the parameters and update the href
      checkUrl.search = new URLSearchParams({
        ...fromEntries(utmParamQueryString),
        ...fromEntries(linkSearchParams)
      }).toString()

      item.href = checkUrl.toString()
    })
  }
}

export default trackUTM
