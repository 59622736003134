const announcementBanner = (flagValue) => {
  const bannerElement = document.querySelector('.global-announcement-banner')
  if (!bannerElement) {
    return
  }

  const linkElement = bannerElement.querySelector('.link')
  const contentElements = bannerElement.querySelectorAll('.content')

  const {
    hidden,
    content = '',
    link = ''
  } = flagValue

  if (hidden) return

  bannerElement.classList.remove('hidden')
  bannerElement.classList.add('flex')
  linkElement.href = link

  contentElements.forEach((contentElement) => {
    contentElement.innerHTML = content
  })
}

export default announcementBanner
