const removeParamsFromUrl = (params) => {
  const currentUrl = new URL(window.location.href)

  params.forEach(param => {
    currentUrl.searchParams.delete(param)
  })

  // eslint-disable-next-line no-restricted-globals
  history.replaceState({}, document.title, currentUrl.toString())
}

export default removeParamsFromUrl
