import getUser from '../user/getUser'

/**
 * Control visibility of Manage Account button based on user subscription and payment status
 */
const setManageAccount = () => {
  const manageSubscription = document.querySelector('.manage-subscription')

  if (manageSubscription) {
    const userData = getUser()
    const hideManageSubscriptionButton = userData.user.user_metadata.hide_manage_subscription_button
    const isSubscribed = userData.user.user_metadata.subscribed

    if (hideManageSubscriptionButton === false
      || (hideManageSubscriptionButton === undefined && isSubscribed)
    ) {
      manageSubscription.classList.add('visible')
      manageSubscription.classList.remove('invisible')
    } else {
      manageSubscription.classList.remove('visible')
      manageSubscription.classList.add('invisible')
    }
  }
}

export default setManageAccount
