/**
 * Control showing/hiding of elements based on user authentication
 */
const toggleElement = (element, isHidden) => {
  if (isHidden) {
    element.classList.add('hidden')
    element.classList.remove('md:flex')
  } else {
    element.classList.remove('hidden')
    element.classList.add('md:flex')
  }
}

export default toggleElement
