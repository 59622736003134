import { ALERT_TIMEOUT } from './constants'

const showAlertBanner = (message, type) => {
  const alertBanner = document.querySelector('.alert-banner')

  if (alertBanner) {
    const alertMessage = alertBanner.querySelector('.message')

    if (type === 'error') {
      alertBanner.classList.add('bg-tcn-red')
    }

    if (type === 'success') {
      alertBanner.classList.add('bg-tcn-green')
    }

    alertMessage.innerHTML = message
    alertBanner.classList.remove('hidden')

    setTimeout(() => {
      alertBanner.classList.add('hidden')
    }, ALERT_TIMEOUT)
  }
}

export default showAlertBanner
