import supabaseClient from '../utils/supabase'

import checkIfUnverifiedEmail from './checkIfUnverifiedEmail'

const refreshUser = async () => {
  try {
    const session = await supabaseClient.auth.refreshSession()

    if (!session || session.error) {
      console.error('Error refreshing session:', session.error)
      return false
    }

    const { data } = session
    localStorage.setItem('userData', JSON.stringify(data))
    checkIfUnverifiedEmail()

    return data
  } catch (error) {
    console.error('An error occurred:', error)
    return false
  }
}

export default refreshUser
