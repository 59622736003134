import { ENV } from '../utils/constants'
import getUser from '../user/getUser'

const { TCN_API_KEY } = ENV

const getSignedUrl = async (videoId) => {
  try {
    const userData = await getUser()
    const { user } = userData
    const { id } = user

    const response = await fetch('/api/sign-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': TCN_API_KEY
      },
      body: JSON.stringify({
        videoId,
        userId: id
      })
    })

    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.json()
    const { signedToken } = data

    return signedToken
  } catch (error) {
    console.error('Error:', error)

    return false
  }
}

export default getSignedUrl
