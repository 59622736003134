const homepageHero = (flagValue) => {
  const {
    title,
    content,
    buttonText,
    buttonLink,
    hideButton = false,
    hideGradient = false,
    backgroundImage,
    backgroundImageBlur = 0
  } = flagValue

  // Ensure we have all the required data
  if (!title || !content || !buttonText || !buttonLink || !backgroundImage) {
    return
  }

  const homepageHeros = document.querySelectorAll('.home-page-hero')
  const homepageHeroImage = document.querySelector('.home-page-hero-image')
  const homepageHeroImageBlur = document.querySelector('.home-page-hero-image-blur')
  const homepageHeroGradient = document.querySelector('.home-page-hero-gradient')

  // Set the background image
  if (homepageHeroImage) {
    homepageHeroImage.src = backgroundImage
    homepageHeroImage.alt = `An image of ${content}`
  }

  // Set the background image blur
  if (homepageHeroImageBlur) {
    homepageHeroImageBlur.style.backdropFilter = `blur(${backgroundImageBlur}px)`
    homepageHeroImageBlur.classList.remove('hidden')
  }

  // Toggle the background gradient
  if (hideGradient && homepageHeroGradient) {
    homepageHeroGradient.classList.add('!hidden')
  }

  if (homepageHeros.length) {
    homepageHeros.forEach((hero) => {
      const heroTitle = hero.querySelector('.hero-title')
      const heroContent = hero.querySelector('.hero-content')
      const heroButton = hero.querySelector('.hero-button')
      const heroTerms = hero.querySelector('.hero-terms')

      // Set the title, content, and button text
      if (heroTitle) {
        heroTitle.textContent = title
      }

      if (heroContent) {
        heroContent.innerHTML = content
      }

      if (heroButton) {
        heroButton.textContent = buttonText
        heroButton.href = buttonLink
      }

      if (hideButton && heroButton) {
        heroButton.classList.add('!hidden')
      }

      // Remove the terms if they exist
      if (heroTerms) {
        heroTerms.remove()
      }
    })
  }
}

export default homepageHero
