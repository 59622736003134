import getUser from './getUser'
import refreshUser from './refreshUser'

const isUserSubscribed = async () => {
  await refreshUser()
  const userData = getUser()

  if (userData) {
    const { user = {} } = userData
    const { user_metadata = {} } = user
    const { subscribed } = user_metadata

    return subscribed
  }

  return false
}

export default isUserSubscribed
