import getUser from '../user/getUser'
import isValidEmail from '../utils/isValidEmail'

import proceedGiftingSubscription from './proceedGiftingSubscription'

import { ANNUAL_PRICE, MAXIMUM_RECIPIENTS } from '../utils/constants'

const emailList = []
const enterEmailSection = document.querySelector('.enter-email')

const getTotalPrice = () => ANNUAL_PRICE * emailList.length

const bindEmailPill = (event) => {
  if (event.data === ',' || event.key === 'Enter') {
    const giftingEmailContainer = document.querySelector('.gifting-emails-container')

    if (!giftingEmailContainer) {
      return
    }

    const emailMessage = giftingEmailContainer.querySelector('.gifting-email-message')
    const emailInputContainer = giftingEmailContainer.querySelector('.email-input-container')
    const emailInput = emailInputContainer.querySelector('.email-input')
    const emailPillsContainer = emailInputContainer.querySelector('.email-pills')
    const countEmail = enterEmailSection.querySelector('.count-email')
    const subTotal = enterEmailSection.querySelector('.sub-total')
    emailMessage.innerHTML = ''
    emailMessage.classList.add('hidden')

    let email = emailInput.value

    if (email.endsWith(',')) {
      email = email.slice(0, -1)
    }

    if (emailList.length === MAXIMUM_RECIPIENTS) {
      emailMessage.innerHTML = `Currently, you can gift Team Tucker to at most ${MAXIMUM_RECIPIENTS} recipients each time, but you can check out and come back to this page to gift more subscriptions.`
      emailMessage.classList.remove('hidden')

      return
    }

    // Check if email is valid
    if (!isValidEmail(email, emailMessage)) return

    const duplicatedEmail = emailList.find((value) => value === email)

    if (duplicatedEmail) {
      emailMessage.innerHTML = 'This email already exists in the list!'
      emailMessage.classList.remove('hidden')

      return
    }

    const pill = document.createElement('div')
    const pillText = document.createElement('span')
    const removeIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    removeIcon.setAttribute('width', '24')
    removeIcon.setAttribute('height', '24')
    removeIcon.setAttribute('viewBox', '0 0 24 24')
    removeIcon.setAttribute('fill', 'none')
    removeIcon.innerHTML = '<g id="Icon 24"><path id="Vector 11" d="M9 9L15 15M15 9L9 15" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle id="Ellipse 1" cx="12" cy="12" r="8" stroke="#282828" stroke-width="2"/></g>'

    pillText.textContent = email
    pill.appendChild(pillText)
    pill.appendChild(removeIcon)
    pill.classList.add('email-pill', 'py-[8px]', 'pl-4', 'pr-[11px]', 'rounded-[25px]', 'bg-[#F1E9DD]', 'flex', 'gap-x-[10px]')
    removeIcon.classList.add('remove-email-icon', 'mt-[2px]', 'cursor-pointer')

    // Add email to list
    emailPillsContainer.appendChild(pill)
    emailList.push(email)

    emailInput.value = ''

    // Update Number of people and Subtotal
    countEmail.textContent = emailList.length
    subTotal.textContent = `$${getTotalPrice()}`

    // Remove email from the list and DOM when click on remove button
    removeIcon.addEventListener('click', (removeEvent) => {
      const removedEmail = removeEvent.target.previousElementSibling.textContent
      const removedEmailIndex = emailList.findIndex((value) => value === removedEmail)

      if (removedEmailIndex >= 0) {
        const emailPill = removeEvent.target.closest('.email-pill')
        if (emailPill) {
          emailPill.remove()
        }
        emailList.splice(removedEmailIndex, 1)

        // Update Number of people and Subtotal
        countEmail.textContent = emailList.length
        subTotal.textContent = `$${getTotalPrice()}`
      }
    })
  }
}

const bindEmailInputEvents = () => {
  const giftingEmailContainer = document.querySelector('.gifting-emails-container')
  if (!giftingEmailContainer) {
    return
  }

  const emailInputContainer = giftingEmailContainer.querySelector('.email-input-container')
  const emailInput = emailInputContainer.querySelector('.email-input')
  const countEmail = enterEmailSection.querySelector('.count-email')
  const subTotal = enterEmailSection.querySelector('.sub-total')

  countEmail.textContent = '0'
  subTotal.textContent = '$0'

  // Add event for inputting comma
  emailInput.addEventListener('input', (event) => {
    bindEmailPill(event)
  })

  // Add event for pressing Enter
  emailInput.addEventListener('keypress', (event) => {
    bindEmailPill(event)
  })
}

const bindProceedGiftingSubscription = () => {
  const giftingEmailContainer = document.querySelector('.gifting-emails-container')
  if (!giftingEmailContainer) {
    return
  }

  const emailMessage = giftingEmailContainer.querySelector('.gifting-email-message')
  const proceedButton = enterEmailSection.querySelector('.proceed-gifting')
  const proceedLoader = proceedButton.querySelector('.spinner')
  const emailInputContainer = giftingEmailContainer.querySelector('.email-input-container')
  const emailInput = emailInputContainer.querySelector('.email-input')
  const countEmail = enterEmailSection.querySelector('.count-email')
  const subTotal = enterEmailSection.querySelector('.sub-total')

  if (!proceedButton) {
    return
  }

  proceedButton.addEventListener('click', async (event) => {
    event.preventDefault()
    const optInCheckbox = enterEmailSection.querySelector('[name="optIn"]')

    emailMessage.innerHTML = ''
    emailMessage.classList.add('hidden')

    proceedButton.classList.add('opacity-50')
    proceedButton.disabled = true
    proceedLoader.classList.remove('hidden')

    const email = emailInput.value

    if (!email && !emailList.length) {
      emailMessage.innerHTML = 'Please add at least one email address'
      emailMessage.classList.remove('hidden')

      proceedButton.classList.remove('opacity-50')
      proceedButton.disabled = false
      proceedLoader.classList.add('hidden')

      return
    }

    if (email && !emailList.length) {
      // Check if email is valid
      if (!isValidEmail(email, emailMessage)) {
        proceedButton.classList.remove('opacity-50')
        proceedButton.disabled = false
        proceedLoader.classList.add('hidden')

        return
      }

      // Update Number of people and Subtotal
      emailList.push(emailInput.value)
      countEmail.textContent = emailList.length
      subTotal.textContent = `$${getTotalPrice()}`
    }

    const userData = getUser()

    localStorage.setItem('isGifting', true)
    localStorage.setItem('giftForm', JSON.stringify({ toEmails: emailList, optIn: optInCheckbox.checked }))

    // Not Authenticated, navigate to SignUp
    if (!userData) {
      window.location = '/signup'

      return
    }

    proceedGiftingSubscription()
  })
}

const gifting = () => {
  bindEmailInputEvents()
  bindProceedGiftingSubscription()
}

export default gifting
