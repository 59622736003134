import refreshUser from '../user/refreshUser'
import setAuthenticated from '../auth/setAuthenticated'
import setSubscribed from '../auth/setSubscribed'
import setManageAccount from '../account/setManageAccount'

/**
 * Refresh the session user and update app state (authenticated, subscription and payment states)
 */
const updateAppState = async () => {
  await refreshUser()
  setAuthenticated()
  setSubscribed()
  setManageAccount()
}

export default updateAppState
