/**
 * Disable button and show loading spinner
 */
const handleButtonLoadingState = (button, isLoading) => {
  const buttonElement = button
  const loader = button.querySelector('.spinner')

  if (isLoading) {
    buttonElement.classList.add('opacity-50')
    buttonElement.disabled = true
    loader.classList.remove('hidden')
  } else {
    buttonElement.classList.remove('opacity-50')
    buttonElement.disabled = false
    loader.classList.add('hidden')
  }
}

export default handleButtonLoadingState
