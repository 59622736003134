import { ENV } from '../utils/constants'
import supabaseClient from '../utils/supabase'
import logout from '../auth/logout'

const { EXPIRY_TIME_FOR_SESSION_IN_SECONDS } = ENV

const getUser = () => {
  let userData = localStorage.getItem('userData')
  userData = JSON.parse(userData)

  if (userData) {
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const { user, session } = userData

    try {
      const date = new Date(user.last_sign_in_at)
      const lastSignInAt = date.getTime()
      const lastSignInExpires = lastSignInAt + EXPIRY_TIME_FOR_SESSION_IN_SECONDS

      // If session is expired logout
      if (session && currentTimestamp > lastSignInExpires) {
        logout()
      }

      // If JWT is expired, refresh the session
      if (!user.remember && session && session.expires_at <= currentTimestamp) {
        supabaseClient.auth.refreshSession()
          .then((response) => {
            if (!response.error) {
              // Save the userData to localStorage
              localStorage.setItem('userData', JSON.stringify(response.data))
            } else {
              logout()
            }
          })
      }
    } catch (error) {
      logout()

      console.error(error)
    }

    return userData
  }

  return false
}

export default getUser
