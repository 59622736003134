const setVideoPlayer = (videoId) => {
  const videoPlayer = document.querySelector('.video-player iframe')
  const thumbnail = document.querySelector('.video-player img')
  const lockOverlay = document.querySelector('.video-player .not-subscribed')

  if (videoPlayer) {
    // Set the video url
    videoPlayer.src = `https://customer-aw5py76sw8wyqzmh.cloudflarestream.com/${videoId}/iframe?autoplay=true&letterboxColor=transparent`
    videoPlayer.classList.remove('hidden')

    // Remove the thumbnail
    if (thumbnail) {
      thumbnail.remove()
    }

    // Remove the lock overlay
    if (lockOverlay) {
      lockOverlay.remove()
    }
  }
}

export default setVideoPlayer
