import validator from 'validator'

const isValidEmail = (email, message, type = 'error') => {
  // Check if email is valid
  if (!validator.isEmail(email)) {
    const messageElement = message

    messageElement.innerHTML = 'Please use a valid email address.'
    messageElement.classList.remove('hidden')

    if (type === 'success') {
      messageElement.classList.add('bg-tcn-green')
    } else {
      messageElement.classList.add('bg-tcn-red')
    }

    return false
  }

  return true
}

export default isValidEmail
