import fromEntries from '../utils/fromEntries'
import trackUTM from '../utils/trackUTM'
import showAlertBanner from '../utils/showAlertBanner'
import removeParamsFromUrl from '../utils/removeParamsFromUrl'

import checkIfUnverifiedEmail from '../user/checkIfUnverifiedEmail'
import getUser from '../user/getUser'
import refreshUser from '../user/refreshUser'
import setAuthenticated from '../auth/setAuthenticated'
import setSubscribed from '../auth/setSubscribed'
import logout from '../auth/logout'

import mobileNav from './mobileNav'
import postSliders from './postSliders'

import runLaunchDarkly from './launchdarkly'

import { ALERT_TIMEOUT, SUBSCRIPTION_PRICE, TCN_CHECKOUT_COOKIE } from '../utils/constants'
import { getCookie, deleteCookie } from '../utils/cookies'

const GLOBAL_FLAG_KEYS = [
  'global-announcement-banner'
]

const HOME_PAGE_FLAG_KEYS = [
  'home-page-join-now-button',
  'home-page-hero'
]

const interviewsCarousel = () => {
  const carouselContainer = document.querySelector('.carousel')

  if (!carouselContainer) {
    return
  }

  const slides = carouselContainer.querySelectorAll('.item')
  const carouselControls = document.querySelectorAll('.carousel-post button')
  const carouselPosts = document.querySelectorAll('.carousel-post')

  let current = 0
  let prev = 2
  let next = 1

  const gotoNum = (number) => {
    current = number
    prev = current - 1
    next = current + 1

    for (let i = 0; i < slides.length; i += 1) {
      slides[i].classList.remove('active')
      slides[i].classList.remove('prev')
      slides[i].classList.remove('next')
    }

    if (next === 3) {
      next = 0
    }

    if (prev === -1) {
      prev = 2
    }

    slides[current].classList.add('active')
    slides[prev].classList.add('prev')
    slides[next].classList.add('next')
  }

  carouselPosts.forEach((post) => {
    const carouselImage = post.querySelector('img')
    const postControl = post.querySelector('button')
    const slideNum = post.dataset.slide

    postControl.addEventListener('click', () => {
      // Remove active states
      carouselPosts.forEach((inactivePost) => {
        const inactiveImg = inactivePost.querySelector('img')
        inactiveImg.classList.remove('shadow-[0_0_0_6px_rgba(255,184,78,1)]')
      })

      carouselControls.forEach((inactiveControl) => {
        inactiveControl.classList.remove('bg-tcn-chocolate', 'text-white', 'border-tcn-chocolate')
        inactiveControl.classList.add('text-tcn-black-secondary')
      })

      // Add active states
      postControl.classList.add('bg-tcn-chocolate', 'text-white', 'border-tcn-chocolate')
      postControl.classList.remove('text-tcn-black-secondary')

      carouselImage.classList.add('shadow-[0_0_0_6px_rgba(255,184,78,1)]')

      // Go to slide
      gotoNum(parseInt(slideNum, 10))
    })
  })
}

const encountersCarousel = () => {
  const carouselContainer = document.querySelector('.encounters-carousel')

  if (!carouselContainer) {
    return
  }

  const carouselPosts = document.querySelectorAll('.encounter-post')

  carouselPosts.forEach((post) => {
    post.addEventListener('click', () => {
      // Set all posts as inactive
      carouselPosts.forEach((inactivePost) => {
        const backgroundImage = inactivePost.querySelector('.bg-image')
        const portrait = inactivePost.querySelector('.portrait')

        backgroundImage.classList.add('saturate-0', 'opacity-60')
        backgroundImage.classList.remove('border-encounter')
        portrait.classList.add('saturate-0')
      })

      const encounterVideo = document.querySelector('.encounter-video iframe')
      const { dataset } = post
      const { video } = dataset

      // Set clicked post as active
      const backgroundImage = post.querySelector('.bg-image')
      const portrait = post.querySelector('.portrait')

      backgroundImage.classList.remove('saturate-0', 'opacity-60')
      backgroundImage.classList.add('border-encounter')
      portrait.classList.remove('saturate-0')

      encounterVideo.src = `https://customer-aw5py76sw8wyqzmh.cloudflarestream.com/${video}/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-aw5py76sw8wyqzmh.cloudflarestream.com%2F${video}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false&letterboxColor=transparent`
    })
  })
}

const handleChangingEmail = async () => {
  const {
    emailChangeSuccess,
    error
  } = fromEntries(new URLSearchParams(window.location.search))

  // Logout and navigate to Login after successfully changing email
  if (emailChangeSuccess === 'true') {
    if (window.location.pathname === '/login/') {
      showAlertBanner('We\'ve successfully changed your email! Please log in with your new account details.', 'success')
    } else {
      await logout()
      window.location = '/login?emailChangeSuccess=true'
    }
    removeParamsFromUrl(['emailChangeSuccess'])
  }

  // Fail to change email
  if (emailChangeSuccess === 'false') {
    showAlertBanner('This kind of email change requires manual verification from customer support. Please reach out over email to <a href="mailto:support@tcn6883.zendesk.com" class="underline">customer support.</a>', 'error')
    removeParamsFromUrl(['emailChangeSuccess'])
  }

  // Internal server error
  if (error === 'token-not-found') {
    showAlertBanner('Something went wrong. Please reach out over email to <a href="mailto:support@tcn6883.zendesk.com" class="underline">customer support</a> so we can resolve this for you.', 'error')
    removeParamsFromUrl(['error'])
  }

  // Changing email link is clicked twice
  if (error === 'token-already-used') {
    showAlertBanner('Your email change was processed successfully!', 'success')
    removeParamsFromUrl(['error'])
  }
}

const handleGiftingSuccess = async () => {
  const {
    giftingSuccess,
    totalSubscribedEmails
  } = Object.fromEntries(new URLSearchParams(window.location.search))
  const redeemSubsAlert = document.querySelector('.redeem-subscription-alert')

  if (giftingSuccess && redeemSubsAlert) {
    await refreshUser()

    const giftForm = JSON.parse(localStorage.getItem('giftForm'))
    const { toEmails, optIn } = giftForm
    const userData = getUser()

    try {
      // Segment event Gifted Subscription
      analytics.track('Gifted Subscription', {
        plan: 'yearly',
        price: SUBSCRIPTION_PRICE.yearly,
        currency: 'USD',
        email: userData.user.email,
        recipients: toEmails,
        optIn
      })
    } catch (err) {
      console.error(err)
    }

    // Clear gift subs data from localStorage
    localStorage.removeItem('giftForm')

    // There's no subscribed email in the list
    if (!totalSubscribedEmails) {
      showAlertBanner('Thanks for gifting Team Tucker!', 'success')
    }

    // There's one subscribed email in the list
    if (Number(totalSubscribedEmails) === 1) {
      showAlertBanner('We\'ve noticed one of your gift recipients is already a Team Tucker member, so we\'ve extended their subscription by one year and notified them of your generosity.', 'success')
    }

    // There's multiple subscribed emails in the list
    if (Number(totalSubscribedEmails) > 1) {
      showAlertBanner('We\'ve noticed some of your gift recipients are already Team Tucker members, so we\'ve extended their subscriptions by one year and notified them of your generosity.', 'success')
    }

    removeParamsFromUrl(['giftingSuccess', 'totalSubscribedEmails'])
  }
}

const initHome = async () => {
  // If checkout success, refresh sessions
  if (window.location.pathname === '/' && window.location.search.includes('?success=true')) {
    // Manually set user_metadata
    let userData = localStorage.getItem('userData')

    if (userData) {
      userData = JSON.parse(userData)

      userData.user.user_metadata.subscribed = true

      localStorage.setItem('userData', JSON.stringify(userData))
    }
  }

  // If checkout success, get checkout data from cookie
  const checkoutData = getCookie(TCN_CHECKOUT_COOKIE)
  if (checkoutData) {
    const decodedCheckoutData = JSON.parse(atob(checkoutData))
    try {
      // Segment event Order Completed
      // Note: Syntax departs from our normal conventions to match Segment's Spec for Podsights
      // https://segment.com/docs/connections/destinations/catalog/podsights/#track
      analytics.track('Order Completed', decodedCheckoutData)

      // Clear checkout cookie
      deleteCookie(TCN_CHECKOUT_COOKIE)
    } catch (err) {
      console.error(err)
    }
  }

  handleGiftingSuccess()

  handleChangingEmail()

  setAuthenticated()
  setSubscribed()

  // Event tracking
  const userData = getUser()
  if (userData) {
    const { user } = userData
    const { id } = user

    try {
      analytics.identify(id)
    } catch (error) {
      console.error(error)
    }
  }
}

const optimizeAccessibility = () => {
  const postSliderItems = document.querySelectorAll('.posts-slider .post-slider-item')

  // Remove aria-hidden on element to avoid issue of
  // [aria-hidden="true"] elements contain focusable descendants
  // https://dequeuniversity.com/rules/axe/4.8/aria-hidden-focus
  if (postSliderItems.length) {
    postSliderItems.forEach((postSliderItem) => {
      postSliderItem.removeAttribute('aria-hidden')
    })
  }
}

const global = () => {
  initHome()
  mobileNav()
  postSliders()
  interviewsCarousel()
  encountersCarousel()
  checkIfUnverifiedEmail()
  trackUTM()
  runLaunchDarkly(GLOBAL_FLAG_KEYS)
  runLaunchDarkly(HOME_PAGE_FLAG_KEYS)
  optimizeAccessibility()
}

export default global
